/* This file is for global styles */
html{
    scroll-behavior: smooth;
    
}

body{
    font-family: "Quicksand", sans-serif;
}
.allContent {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}