.nav{
  height: 0;
}
.mainNavBar {
    width: 120px;
    padding-top: 5%;
    margin-right: 10px;
    background-color: #4a4747;
    line-height: 60px;
    border-radius: 0px 0px 25px 25px;
    text-align: center;
    background-color: #363535;
  }

  /* Overwritting  */
  .nav-pills .nav-link{
    border-radius: 25px;
    color: white;
  }

.chosen {
  background-color: #83c1ff;
  border-radius: 25px;
}


  @media only screen and (max-width: 600px) {
    .mainNavBar {
      display: none;
    }
  }