.welcomeScreen{
    height: 100vh;
    width: 100%;
    background:url(../../../assets/images/coverPic.png) center center / cover;
    position:relative;
}

.welcomeMessage{
    padding-top: 30%;
    text-align: center;
    color: white;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 8vw;
  
    /* font-family: 'Source Sans Pro', sans-serif; */
  
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),0px 18px 23px rgba(0, 0, 0, 0.1);
}

.boxWithArrow{
    background-color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right:10px;
    width: 120px;
    height: 70px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border: 1px solid white;
}

.downArrowPic {
    width: 35%;
    height: 35%;
    margin-left: 35px;
    margin-top: 25px;
    animation: blink 2s infinite;
  }


@media only screen and (max-width: 600px) {

    .welcomeScreen{
        margin-top: 0;
    }

    .welcomeMessage{
        padding-top: 150%;
    }

    .segueLink{
        display: none;
    }
    
  }
