.customNavBar{
    width: 80%;
    margin: 0 auto;
}

.navbar{
    color: white;
    font-size: 25px;
    text-shadow: 0px 4px 5px rgb(0 0 0 / 40%);
}

.navbar-brand{
    font-size: 25px;
}

.navUnderLine {
    border-width: 1px;
    background-color: rgb(255, 255, 255, 0.7);
}
.phoneVersion{
    display: none;
}



@media only screen and (max-width: 600px) {
    .normalScreen {
      display: none;
    }
    .customNavBar{
        width: 100%;
    }
    .phoneVersion{
        display: block;
        /* box-shadow: 0 5px 5px #353535; */
    }
    .phoneScreenNavItem{
        font-size: 15px;
    }

    .scrolled {
        background-color: #7facda;
        transition: background-color 200ms linear;
    }
  }

