.secondPage{
    height: 100vh;
    margin: 0 auto;
    background-color: transparent;
}

#profilePic{
    width: 350px;
    height: 350px;
    float: right;
    margin-right: 5%;
    border: 5px solid rgb(51, 51, 51);
}

.details{
    text-align: left;
}

.name{
    color: #83c1ff;
    font-size: 5vw;
    line-height: normal;
}

.info{
    color:black;
    font-size: 2vw;
}

/* For letters of Google */
.letter_g{
    color: #4384f3;
}
.letter_o{
    color: #ea4435;
}
.letter_o2{
    color: #fbbc04;
}
.letter_g2{
    color: #4384f3;
}
.letter_l{
    color: #33a952;
}
.letter_e{
    color: #ea4435;
}


@media only screen and (max-width: 600px) {
   
    #profilePic{
        width: 256px;
        height: 256px;
        border: 5px solid rgb(51, 51, 51);
        margin-right: 0;
    }
    .name{
        color: #83c1ff;
        font-size: 64px;
        text-align: center;
    }
    
    .info{
        color:black;
        font-size: 16px;
        text-align: center;

    }
  }