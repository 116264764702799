#LeadershipPage{
    min-height: 100vh;
    background-color: #376288;
}
#LeadershipPage .mainTitle {
    padding-top: 2%;
    text-align: center;
    font-size: 80px;
    line-height: normal;
    color: white;
  }

#LeadershipPage   .cardContainer {
    width: 60%;
    height: 200px;
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  #LeadershipPage .boxHidden {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 5px solid rgb(68, 68, 68);
    border-radius: 20px;
    color : white;
    overflow-y: auto;
  }
  
  #LeadershipPage .textInsideBox {
    padding-top: 30px;
    padding-left: 20px;
  }
  
  #LeadershipPage .textInsideBox h4 {
    text-align: center;
  }
  
  #LeadershipPage .stack-top {
    z-index: 9;
    padding-top: 20px;
    background-color: #21496d;
    transition: 0.3s;
    overflow: hidden;
    text-align: center;
    color: white;
  }
  
  #LeadershipPage .cardContainer:hover .stack-top {
    height: 0%;
    border: 0px;
    padding-top: 0px;
  }


  @media only screen and (max-width: 600px) {

    #LeadershipPage .mainTitle {
        padding-top: 2%;
        text-align: center;
        font-size: 40px;
        line-height: normal;
        color: white;
      }
    
    #LeadershipPage   .cardContainer {
        width: 80%;
        height: 150px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 60px;
      }
      #LeadershipPage .boxHidden {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 5px solid rgb(68, 68, 68);
        border-radius: 20px;
        color : white;
        overflow-y: auto;
      }
      
      #LeadershipPage .textInsideBox {
        padding-top: 30px;
        padding-left: 20px;
      }
      
      #LeadershipPage .textInsideBox h4 {
        text-align: center;

      }
      
      #LeadershipPage .stack-top {
        z-index: 9;
        padding-top: 20px;
        background-color: #21496d;
        transition: 0.3s;
        overflow: hidden;
        text-align: center;
        color: white;
        font-size: 15px;
        overflow-y: auto;
      }

      #LeadershipPage .stack-top h2{
        font-size: 20px;
      }
      
      #LeadershipPage .cardContainer:hover .stack-top {
        height: 0%;
        border: 0px;
        padding-top: 0px;
      }

      #LeadershipPage .textInsideBox {
        padding-top: 20px;
        padding-left: 20px;
      }
    
    
  }
  